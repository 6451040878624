import {Route, Routes} from 'react-router-dom'

import UserPage from './pages/User'
import NodePage from './pages/NodePage'
import HomePage from './pages/Home'
import EmployeePage from './pages/EmployeePage'
import ProjectPage from './pages/ProjectPage'
import RolePage from "./pages/RolePage";
import LoginPage from "./pages/LoginPage";
//import {useEffect} from "react";
//import PageNode from './pages/Node';

const AppRoutes= ()=>{
    return <Routes>
        <Route exact path="" element={<HomePage />} />
        <Route exact path="/home" element={<HomePage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/node" element={<NodePage />} />
        <Route path="/employee" element={<EmployeePage />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/role" element={<RolePage />} />
        <Route path="/login" element={<LoginPage />} />
    </Routes>
  {/*<this.state.currentContent/>*/}
}
export default AppRoutes