import { Component } from 'react';

import EmployeeListPc from "../cmp/employee/ListPc"
import EmployeeListM from "../cmp/employee/ListM"
import PubSub from 'pubsub-js';
import Helper from "../func";

class EmployeePage extends Component{
    constructor(props){
      super(props)
      this.state={
        createOpenEditor:false
      }
    }
    msgTopic="EmployeePage"

    sendMsg(cmd){
        PubSub.publish(this.msgTopic,cmd);
    }
    
    onCreate=(e)=>{
        this.setState({createOpenEditor:true});
    }
    onClose=()=>{
        this.setState({createOpenEditor:false});
    }
    render(){
        const isM = Helper.isM();
        return <div>

            {isM?<EmployeeListM/>:<EmployeeListPc/>}
        </div>
    }
}
export default EmployeePage;