import {
    Avatar,
    Button,
    Drawer,
    List,
    Skeleton,
    Space,
    Modal,
    Input,
    Select,
    Popconfirm,
    Switch,
    Row,
    Col,
    message, Form, Table
} from 'antd';
import { Context } from '../Context';
import React, {useContext, useEffect, useState} from 'react';
import Helper from "../../func"
import UserCreator from './Creator';
import AuthPanel from "./AuthPanel";
import Selector from "../fields/Selector";
const { Search } = Input;
const { Option } = Select;

const columns=[
    {
        title: '账号',
        dataIndex: 'u_account'
    },
    {
        title: '昵称',
        dataIndex: 'u_nickname'
    },
    {
        title: '员工信息',
        dataIndex: 'em_num'
    }
];

const UserList = () => {
    const [searchForm] = Form.useForm();
    const setting = useContext(Context)
    const userStatus=setting.u_status||{}
    const [initLoading, setInitLoading] = useState(true);

    const [list, setList] = useState([]);
    const [searchParam, setSearchParam] = useState({field:'u_nickname',value:'',status:'-'});
    const [creating,setCreating] = useState(false);
    const [bindEmUid,setBindEmUid] = useState(false);
    const [authUserId,setAuthUserId] = useState(false);
    const [actionStatus,setActionStatus] = useState({disableSyncBtn:false});

    const updateActionStatus=(data)=>{
        setActionStatus({...actionStatus,...data})
    }

    const refresh=()=>{
        const url = Helper.serviceUrl('user/get_list')
        let _param = {};
        if(searchParam.value){_param[searchParam.field] = searchParam.value}
        if(searchParam.u_status &&  searchParam.u_status!=='-'){_param.u_status = searchParam.u_status}
        Helper.http.get(url,_param,function(res){
            const listData = res.result.map(e=>{
                e.key = e.u_id;
                //e._r = Math.random();
                return e;
            })
            setInitLoading(false);
            setList(listData);
        })
    }

    const onSearch=(values)=>{
        setSearchParam({...values})
        //this.refresh()
    }

    const onSync = () => {
        const url = Helper.serviceUrl('user/sync_user');
        updateActionStatus({disableSyncBtn:true})
        Helper.http.get(url,{},function(res){
            Modal.success({
                content: res.msg,
            });
            updateActionStatus({disableSyncBtn:false})
        })
    }


    const onResetPasswrod=(uId)=>{
        const url = Helper.serviceUrl('user/reset_pwd')
        Helper.http.get(url,{u_id:uId},function(res){
            Modal.success({
                content: res.msg,
            });
        })
    }

    const onResetStatus=(uId,enabled)=>{
        const status = (enabled?userStatus.enabled:userStatus.disabled);
        const t=[];
        list.forEach((v,i)=>{
            if(v.u_id===uId)v.u_status = status
            t[i] = v
        })
        setList(t);
        const url = Helper.serviceUrl('user/set_status')
        console.log(userStatus);
        Helper.http.get(url,{u_id:uId,u_status:status},function(res){
            if(!res.success){
                refresh();
                message.error(res.msg||"操作出错")
            }else{


                message.success(res.msg||"操作完成")
            }
        })
    }

    const onBindEm = (uId,emId)=>{
        const url = Helper.serviceUrl('user/bind_em')
        Helper.http.get(url,{u_id:uId,em_id:emId},function(res){
            if(res.success){message.success(res.msg||"操作完成");}
            else {message.error(res.msg||"操作出错");}
            refresh()
        });
    }

    const onUnbindEm = (uId)=>{
        const url = Helper.serviceUrl('user/unbind_em')
        Helper.http.get(url,{u_id:uId},function(res){
            if(res.success){message.success(res.msg||"操作完成");}
            else {message.error(res.msg||"操作出错");}
            refresh()
        });
    }

    const emDescRenderer=(v)=>{
        return  [
            '工号:'+v.em_num,
            '状态:'+(v._em_status__text||v.em_status),
            //'性别:'+(v._em_sex__text||v.em_sex),
        ].join(" ")
    }

    useEffect(() => {refresh()}, [searchParam]);
    const header =()=>{
        return <Row>
            <Col span={18}>
                <Form form={searchForm} onFinish={onSearch} initialValues={searchParam}>
                    <Space>
                        <Form.Item name="u_status" style={{width:"100px"}}>
                            <Select>
                                <Option value="-">不限制</Option>
                                <Option value="9">启用中</Option>
                                <Option value="1">已禁用</Option>
                            </Select>
                        </Form.Item>
                        <Input.Group compact>
                            <Form.Item name="field" style={{width:"30%"}}>
                                <Select>
                                    <Option value="u_nickname">昵称</Option>
                                    <Option value="u_account">账号</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="value" style={{width:"66%"}} >
                                <Search placeholder="input search text" onSearch={()=>searchForm.submit()} enterButton />
                            </Form.Item>
                        </Input.Group>
                    </Space>
                </Form>
            </Col>
            <Col span={6}>
                <Button style={{float:"right"}} onClick={()=>setCreating(true)}>添加</Button>
                <Button style={{float:"right"}} onClick={()=>onSync()} disabled={!!actionStatus.disableSyncBtn}>同步</Button>
            </Col>
        </Row>
    }
    const itemActionTools=(item,appends=[])=>{
        return <Space>
            <a onClick={()=>setAuthUserId(item.u_id)}>授权</a>
            <Popconfirm
                placement="bottomRight"
                title="你确定要重置该用户的密码吗？"
                onConfirm={()=>onResetPasswrod(item.u_id)}
                okText="确认重置"
                cancelText="我误点了"
            >
                <a>重置密码</a>
            </Popconfirm>
            {item.em_id > 0 ?
                <Popconfirm
                    placement="bottomRight"
                    title="你确定要解绑吗？"
                    onConfirm={() => onUnbindEm(item.u_id)}
                    okText="确认解绑"
                    cancelText="我误点了"
                >
                    <a>解绑员工</a>
                </Popconfirm>
                :
                <a onClick={() => setBindEmUid(item.u_id)}>绑定员工</a>
            }
            {[...appends]}
        </Space>
    }
    const pcList=()=>{
        return <Table
            rowKey="u_id"
            dataSource={list}
            columns={[...columns,
                {
                    title: '操作',
                    dataIndex: 'u_status',
                    render: (_, item) => <Switch checkedChildren="已启用" unCheckedChildren="已禁用" onChange={(checked)=>onResetStatus(item.u_id,checked)} checked={item.u_status==userStatus.enabled}  />
                },
                {
                    title: '操作',
                    dataIndex: 'u_id',
                    render: (_, record) =>(itemActionTools(record))
                }
            ]}
        />
    }
    const mList=()=>{
        return <List
            loading={initLoading}
            itemLayout="horizontal"
            dataSource={list}
            renderItem={(item) => (
                <List.Item
                    actions={itemActionTools(item,[
                        <Switch checkedChildren="已启用" unCheckedChildren="已禁用" onChange={(checked)=>onResetStatus(item.u_id,checked)} checked={item.u_status==userStatus.enabled}  />
                    ])}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            avatar={<Avatar src={item.project_logo} />}
                            title={item.u_nickname}
                            description={item.u_account+' <工号:'+item.em_num+'>'}
                        />
                    </Skeleton>
                </List.Item>
            )}
        />
    }

    return (
        <>
            {header()}
            <Skeleton type='list' statistic={false} loading={initLoading} active paragraph={{ rows: 10 }}>
                {Helper.isM()?mList():pcList()}
            </Skeleton>
            <Drawer visible={creating} onClose={()=>setCreating(false)}>
                <UserCreator/>
            </Drawer>
            <Drawer visible={bindEmUid} onClose={()=>setBindEmUid(false)}>
                <Selector afterSelect={(emId)=>{onBindEm(bindEmUid,emId);}}
                          processItemData={(item)=>({key:item.em_id,avatar:item._em_avatar__url,title:item.em_name,description:emDescRenderer(item),sex:item.em_sex})}
                          serviceUrl={Helper.serviceUrl('employee/get_list')}
                          searchFields={[
                              {value:"em_name",text:"姓名",default:true},
                              {value:"em_num",text:"工号"}
                          ]}
                />
            </Drawer>
            <Drawer width={600} visible={authUserId?true:false} onClose={()=>setAuthUserId(0)}>
                <AuthPanel uId={authUserId}/>
            </Drawer>
        </>
    );
};

export default UserList;