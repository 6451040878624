import {Divider, List} from "antd";
import React, {useEffect, useState} from "react";
import Helper from "../../func";

/**
 *
 * @param {{int}} props.xnodeId
 * @return {JSX.Element}
 * @constructor
 */
const BrowserAccess=(props)=>{
    const xnodeId = props.xnodeId||0;

    const [userData,setUserData] = useState([])
    const [roleData,setRoleData] = useState([])
    const loadData=()=>{
        if(xnodeId>0) {
            const url = Helper.serviceUrl('node/access')
            Helper.http.get(url, {node_id: xnodeId}, function (res) {
                setUserData(res.user_access || [])
                setRoleData(res.role_access || [])
            })
        }
        else{
            setUserData([])
            setRoleData([])
        }
    }

    const takeBackAccesOfUser=(uId)=>{
        const xnodeId = props.xnodeId
        const url = Helper.serviceUrl('node/revoke_by_user')
        Helper.http.get(url,{node_id:xnodeId,u_id:uId},function (){
            loadData();
        })
    }
    const takeBackAccesOfGroup=(groupId)=>{
        const xnodeId = props.xnodeId
        const url = Helper.serviceUrl('node/revoke_by_role')
        Helper.http.get(url,{node_id:xnodeId,role_id:groupId},function (){
            loadData();
        })
    }
    useEffect(function (){
        loadData();
    },[xnodeId])
    return <>
        <List
            size="large"
            header={<div>权限组</div>}
            //footer={<div>Footer</div>}
            bordered
            rowKey="role_id"
            dataSource={roleData}
            renderItem={(item) =>(
                <List.Item
                    actions={[
                        <a onClick={()=>takeBackAccesOfGroup(item.role_id)}>回收</a>
                    ]}
                >
                    <List.Item.Meta
                        title={item.role_name}
                    />
                </List.Item>
            )}
        />
        <Divider></Divider>
        <List
            bordered
            rowKey="u_id"
            header={<div>账号</div>}
            itemLayout="horizontal"
            dataSource={userData}
            renderItem={(item) => (
                <List.Item
                    actions={[
                        <a onClick={()=>takeBackAccesOfUser(item.u_id)}>回收</a>
                    ]}
                >
                    {item.u_account} / {item.u_nickname}
                </List.Item>
            )}
        />
    </>
}
export default BrowserAccess;