import axios from 'axios'
const __={};
const Helper={
    isM(){
        return window.screen.width<600;
    },
    resetKeys(data,keys,defaultData={}){
        const r = {...defaultData};
        for(let k in data){
            if(typeof keys[k]==='undefined'){
                r[k] = data[k];
            }else{
                r[keys[k]] = data[k];
            }
        }
        return r;
    },
    mergeExec(k,func,delay=400){
        if(__[k])clearTimeout(__[k]);
        __[k] = setTimeout(func,250);
    },
    pathname(p){

        return p;
    },
    serviceUrl(p){
        //if(p.indexOf('node/')===0)p = p.replace(/^node\//,'xnode/');
        return '/service/'+p;
    },
    http:{
        get(url,params,callback,errCallback){
            axios.get(url, {
                params:params
            }).then(function (response) {
                callback(response.data||{});
            }).catch(function (error) {
                const response = error.response||{}
                const data = response.data||{}
                if(typeof errCallback==='function'){
                    errCallback(data,error);
                }else{
                    //console.error(error);
                    alert(data.message||error.message||'请求出错，请检查网络')
                }
            });
        },
        post(url,params,callback,errCallback){
            axios.post(url, params).then(function (response) {
                callback(response.data||{});
            }).catch(function (error) {
                const response = error.response||{}
                const data = response.data||{}
                if(typeof errCallback==='function'){
                    errCallback(data,error);
                }else{
                    //console.error(error);
                    alert(data.message||error.message||'请求出错，请检查网络')
                }
            });
        }
    }
};
export default Helper;