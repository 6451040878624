import { Button,  Divider,  Form, Input, Skeleton, message } from 'antd';
import React, { useEffect, useState } from 'react';
import Helper from '../../func';
//import PubSub from 'pubsub-js';
const { TextArea } = Input;
const service=(p)=>{
   return Helper.serviceUrl('node/'+p);
}

// const rules={
//   node_code:{
//     process:function(v){
//       return (v||'').replace(/[^\w-]+/g,'')
//     },
//     isVaild:function(v){
//       return this.process(v)!==v;
//     }
//   }
// }

const XnodeEditor = (props) => {
  const defaultData = {node_parent_id:'',project_id:0,node_name:"",node_comment:'',node_code:""};
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(true)

  const isCreate = props.xnodeId==='+';
  useEffect(()=>{
    if(props.xnodeId==='+'){
      //添加
      //console.log(props.defaultData);
      form.setFieldsValue({...defaultData,...(props.defaultData||{})})
      setLoading(false)
    }
    else if(props.xnodeId){
      //编辑
      const url = service('details')
      Helper.http.get(url,{node_id:props.xnodeId},function(res){
        const d = res.result||{};
        form.setFieldsValue({...defaultData,...d})
        console.log({...defaultData,...d})
        setLoading(false)
      })
    } 
  },[props.xnodeId,props.defaultData]);// eslint-disable-line


 
  const onFinish = (values) => {
    const url = isCreate?service('create'):service('save')
    const data = {...defaultData,...values};
    if(data.node_id && data.node_id!==props.xnodeId){
      console.log(data.node_id,props.xnodeId);
      message.error('数据错误(node_id)');return;
    }
    Helper.http.post(url,data,function(){
      //if(msgTopic) PubSub.publish(msgTopic,{cmd:'node_form_saved'})
      message.success('已保存')
      if(typeof props.afterSave==='function') props.afterSave();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Skeleton loading={loading} active >
      <Form
        name="basic"
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={defaultData}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="ID" name="node_id" hidden={true} >
          <Input readOnly/>
        </Form.Item>
        <Form.Item label="父节点" name="node_parent_id" hidden={!isCreate}>
          <Input readOnly />
        </Form.Item>
        <Form.Item label="所属项目" name="project_id" hidden={!isCreate}>
          <Input readOnly/>
        </Form.Item>
        
        <Form.Item
          label="名称"
          name="node_name"
          rules={[
            {
              required: true,
              message: '请输入节点名称',
            },
          ]}
        >
          <Input name="node_name"  />
        </Form.Item>

        <Form.Item
          label="代码"
          name="node_code"
          rules={[
            {
              required: true,
              message: '请填写',
            },
          ]}
        >
          <Input name="node_code" />
        </Form.Item>

        <Form.Item name="node_comment"  label="备注" >
          <TextArea rows={4} name="node_comment"/>
        </Form.Item>

          <Divider/>
        <div style={{textAlign:"right"}}>
          <Button type="primary" htmlType="submit" style={{margin:"auto",width:"160px"}}>
          提交
          </Button>
        </div>
      </Form>
    </Skeleton>
  );
};

export default XnodeEditor;