import {MenuOutlined} from '@ant-design/icons';
import { Layout,Drawer } from 'antd';
import React from 'react';
 
import Cmp from '../Cmp';
import AppRoutes from '../../AppRoutes';
import MainMenu from './Menu';

const { Header,  Content } = Layout;
class App extends Cmp{
    constructor(props){
      super(props)
      this.state={
        visibleMenu:false,
        //currentContent:function(){return <span>content2</span>;}
      };
    }
    hideMenu=()=>{
      this.setState({visibleMenu:false})
    }
    showMenu=()=>{
      this.setState({visibleMenu:true})
    }
    render(){
      return (
        <Layout className='main-view site-layout'>
          <Header
              className="_header"
            >
              <MenuOutlined onClick={()=>{this.setState({visibleMenu:!this.state.visibleMenu})}}/>
              
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: '1px',
                padding:"15px",
                minHeight: 280,
              }}
            >            
              <AppRoutes/>
            </Content>
            <Drawer className='dark-drawer' title="主菜单" headerStyle={{background:"#001529",color:"#fff"}} bodyStyle={{padding:0,background:"#001529"}} width="260px" placement="left" onClose={this.hideMenu} visible={this.state.visibleMenu}>
              <MainMenu afterSelect={()=>this.hideMenu()}/>
            </Drawer>
        </Layout>
      );
    }
  }
  
  export default App;