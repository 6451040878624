import { Avatar, Button, Divider, List,Skeleton } from 'antd';
import EmployeeList from './List';
import EmployeeEditor from './Editor';


class EmployeeListM extends EmployeeList {
  formatData(){
    const data = [];
    this.state.data.forEach((item)=>{
      const title = item.em_name;
      const desc = 'ID:'+item.em_id
      data.push({...item,...{title:title,desc:desc}})
    })
    return data;
  }
  render(){
    const editEm = this.state.edit_em
    const data = this.formatData();
    return <>
    <Button  onClick={()=>this.createItem()}>创建</Button>
            <Divider />
    <Skeleton loading={this.state.loading} active paragraph={{ rows: 5 }}>
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item actions={[
            <a onClick={()=>{ this.editItem(item.em_id,item) }}>编辑</a>
        ]}
          //onClick={()=>{alert(item.title)}}
        >
          <List.Item.Meta
            //avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
            title={item.title}
            description={item.desc}
          />
        </List.Item>      
      )}
    />
    
    </Skeleton>
      <EmployeeEditor
          afterSaved={()=>{this.closeEditor();this.loadData()}}
          emId={editEm.em_id||-1}
          drawerCfg={{visible:!!editEm.em_id,onClose:()=>this.closeEditor()}}
      />

    </>
  }
}

export default EmployeeListM;