//simport React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
//import { Tabs, Radio, Space } from 'antd';
import XnodeList from '../cmp/xnode/List';
import Project from '../cmp/xnode/Project';
import { Row, Col} from 'antd';
import React, { useEffect } from "react";
import PubSub from "pubsub-js";
//const { TabPane } = Tabs;

const msgTopic = 'xnode_page';






const NodePage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const projectId = searchParams.get('id')


    
    useEffect(()=>{
        const token = PubSub.subscribe(msgTopic,(_,b)=>{
            const cmd = b.cmd||'';
            if(cmd === 'switch_project'){
                setSearchParams('?id='+b.projectId,{replace:true})
            }
        });

        return ()=>PubSub.unsubscribe(token)
    },[]);
  return (
     <Row justify="center" gutter={24} align="top" style={{height:"100%","alignItems":"stretch"}}>
          <Col span={6} style={{borderRight:"1px solid #f0f0f0"}}><Project msgTopic={msgTopic} /></Col>
          <Col span={18}><XnodeList msgTopic={msgTopic} projectId={projectId} /></Col>
      </Row>
  );
};

export default NodePage;


