import {Divider, message, Skeleton, Spin, Tree,Typography} from "antd";
import {useEffect, useState} from "react";
import Helper from "../../func";
const { Text } = Typography;

const RoleAccess=(props)=>{
    const {roleId} = props
    const [loadingNode,setLoadingNode]=useState(true)
    const [loadingAccess,setLoadingAccess]=useState(true)

    const [nodeKeysSelected,setNodeKeysSelected]=useState([])
    const [nodeTrees,setNodeTrees] = useState([])
    const [title,setTitle] = useState('')

    const onCheck =(selKeys)=>{
        setNodeKeysSelected(selKeys)
        Helper.mergeExec('RoleAccessSave',function(){
            Helper.http.post(Helper.serviceUrl('role/set_access'), {role_id:roleId,access:JSON.stringify(selKeys)},function (res){
                res.result = res.result||[]
                res.msg = res.msg||false;
                setNodeKeysSelected(res.result)
                setLoadingAccess(false)
                if(res.success) {
                    message.success(res.msg||'已保存')
                }else{
                    message.error(res.msg||'保存出错,请重试')
                }
            })
        },1000);
    }

    useEffect(function (){
        setLoadingAccess(true)
        Helper.http.get(Helper.serviceUrl('role/access'), {role_id:roleId},function (res){
            const access = res.access||[]
            const role = res.role|| {}
            setNodeKeysSelected(access)
            setTitle(role.role_name||'');
            setLoadingAccess(false)
        })
    },[props.roleId])

    useEffect(function (){
        setLoadingNode(true);
        Helper.http.get(Helper.serviceUrl('role/all_node'),{},function (res){
            res.result = res.result||[]
            setNodeTrees(res.result)
            setLoadingNode(false)
        })
    },[])

    return (!props.roleId || props.roleId<1)?'':(
        <Skeleton loading={loadingNode} active={true}>
            <Spin spinning={loadingAccess}>
                <Text strong>{title}</Text>
                <Divider></Divider>
                <Tree onCheck={onCheck} checkable checkedKeys={nodeKeysSelected}  treeData={nodeTrees} fieldNames={{title:"node_name",key:"node_id",children:"children"}}/>
            </Spin>
        </Skeleton>
    )
}
export default RoleAccess