import {Avatar, Button, List, Skeleton, Input, Select, Form} from 'antd';
import React, { useEffect, useState} from 'react';
import Helper from "../../func"
const { Search } = Input;
const { Option } = Select;

/**
 * 
 * @param {{processItemData,afterSelect,processField,itemKey:"default key",searchParam:{},searchFields:{fieldsValue:"fieldText","..."}}} props
 * @param {function} props.processItemData
 * @param {[{value:"string 字段",text:"string 显示文本",default:"bool"}]} props.searchFields
 * @param {function} props.afterSelect function(key,item)
 * @returns 
 */
const Selector = (props) => {

  const [initLoading, setInitLoading] = useState(true);
  const [list, setList] = useState([]);
  const [searchParam, setSearchParam] = useState(props.searchParam||{});
  const [searchForm] = Form.useForm();

  /**
   * 生成搜索字段
   */
  const[initSearchValues,options] = ((fields)=> {
    const formVal = {value: ""}, options = [];
    if (fields.length>0) {
      for (let i = 0; i < fields.length; i++) {
        const {value, text} = fields[i]
        options.push(<Option value={value}>{text}</Option>)
        if (!!fields[i].default) {
          formVal.field = value
        }
      }
    }
    return [formVal,options];
  })(props.searchFields)

  useEffect(() => {refresh()}, []);

  /**
   * 获取数据
   */
  const refresh=(param = {})=>{
    const url = props.serviceUrl
    //let param = {};
    if(searchParam.value){param = {...searchParam}}
    Helper.http.get(url,param,function(res){
      const _data = res.result.items||[];
      const listData = _data.map(e=>{
        if(typeof props.processItemData==='function') return props.processItemData(e)
      })
      console.log(listData)
      setInitLoading(false);
      setList(listData);
    })
  }

  /**
   * 
   * @param {string} src
   * @param {string} name
   * @param {number} sex
   * @returns {JSX.Element}
   */
  const getAvatar=(src,name,sex)=>{
    sex = sex!=1?0:1;
    return src?<Avatar src={src} />:<Avatar className={'xx-avatar-'+sex}>{name}</Avatar>
  }
  /**
   * 
   * @param {string} field 
   * @param {{}} item 
   * @returns {string}
   */
  const processField=(field,item)=>{
    if(typeof props.fieldCallback==='function'){
      return props.fieldCallback(field,item)
    }else{
      return item[field]||'';
    }
  }

  const handleSelect=(item)=>{
    if(props.afterSelect||false)props.afterSelect(item.key,item);
  }

  const handleSearch=(values)=>{
    const searchParam = {};
    values.value = values.value.replace(/^\s+|\s+$/g,'')
    if(values.value){
      searchParam[values.field] = values.value;
    }
    refresh(searchParam)
  }


  return (
    <>
      <Form onFinish={handleSearch} form={searchForm} layout="inline" initialValues={initSearchValues}>
      <Input.Group compact>
        {
          options.length<1?null:
          <Form.Item name="field" style={{width:"40%"}} rules={[{require:true}]} >
              <Select>
                {options}
              </Select>
          </Form.Item>
        }

        <Form.Item name="value" style={{width:"55%"}}>
          <Search placeholder="input search text" onSearch={()=>{searchForm.submit()}} enterButton />
        </Form.Item>
      </Input.Group>
      </Form>

      <List
        loading={initLoading}
        itemLayout="horizontal"
        dataSource={list}
        rowKey={props.itemKey||"key"}
        renderItem={(item) => (
          <List.Item
            actions={[
                <Button onClick={()=>handleSelect(item)}>选择</Button>
            ]}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={getAvatar(processField('avatar',item),processField('title',item),processField('sex',item))}
                title={processField('title',item)}
                description={processField('description',item)}
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </>
  );
};

export default Selector;