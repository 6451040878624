/**
 *
 * @param {int} props.uId
 * @return {JSX.Element}
 * @constructor
 */
import {useEffect, useState} from "react";
import {Divider, message, Skeleton, Spin, Transfer, Tree} from "antd";
import Helper from "../../func";
import './AuthPanel.css';


/**
 *
 * @param {int} props.uId
 * @return {JSX.Element}
 * @constructor
 */
const AuthPanel = (props)=>{
    const uId = props.uId
    const [loading,setLoading] = useState(true);
    const [loadingAccess,setLoadingAccess] = useState(false)
    const [nodeTrees,setNodeTrees] = useState([]);


    const [d,setD] = useState({

        role_access:[],
        user_access: [],
        role_ids:[],
        node_tree:[],
        role_list: []

    })


    // const loadNodeTree=()=>{
    //     setLoading(true)
    //     Helper.http.get(Helper.serviceUrl('user/get_node_tree'),{u_id:props.uId},function (res){
    //         const r = res.result||[]
    //         setD({...d,node_tree:r});
    //         setLoading(false);
    //     })
    // }

    const loadAccess=()=>{
        setLoading(true);
        Helper.http.get(Helper.serviceUrl('user/get_access_info'),{u_id:props.uId},function (res){
            setD({...d,...res.result});
            setLoading(false);
        })
    }
    const eachNode=(nodeList,roleAccess,nodeAccess)=>{
        const rAccess = '/'+(roleAccess||[]).join('/')+'/';
        const nAccess = '/'+(nodeAccess||[]).join('/')+'/';
        console.log(rAccess);
        function _(list){
            let disableQty=0
            for(let i=0;i<list.length;i++){
                let disable = (rAccess.indexOf('/'+list[i].node_id+'/')>=0 && nAccess.indexOf('/'+list[i].node_id+'/')<0)
                if(!!list[i].children && list[i].children.length>0){
                    const [children,disabledAllChildren] = _(list[i].children);
                    list[i].children = children
                    disable = disable||disabledAllChildren
                }

                list[i].disabled = disable
                if(disable)disableQty++
            }
            return [list,(disableQty>0 && disableQty===list.length)]
        }
        const r = _(nodeList||[])
        return r[0]
    }



    const onRoleSelectChange = (newTargetKeys) => {
        setLoadingAccess(true);
        Helper.mergeExec('user/set_role',function(){
            Helper.http.post(Helper.serviceUrl('user/set_role'), {u_id:uId,access:JSON.stringify(newTargetKeys)},function (res){
                const r = res.result
                if(r)setD({...d,...res.result});
                if(res.success) {
                    message.success(res.msg||'已保存')
                }else{
                    message.error(res.msg||'保存出错,请重试')
                }
                setLoadingAccess(false);
            })
        },1000);
    };

    const onUserAccessChange =(selKeys)=>{
        setD({...d,...{user_access:selKeys}});
        Helper.mergeExec('user/set_access',function(){
            Helper.http.post(Helper.serviceUrl('user/set_access'), {u_id:uId,access:JSON.stringify(selKeys)},function (res){
                const r = res.result
                if(r)setD({...d,...res.result});
                if(res.success) {
                    message.success(res.msg||'已保存')
                }else{
                    message.error(res.msg||'保存出错,请重试')
                }
            })
        },1000);
    }

    useEffect(function (){
        //loadNodeTree();
        loadAccess();
    },[props.uId]);

    useEffect(function (){
        setNodeTrees(eachNode(d.node_tree,d.role_access,d.role_ids));
    },[d.node_tree,d.role_access,d.role_ids])


    return (
        <Spin spinning={loadingAccess} wrapperClassName="xx-auth-panel xx-stretch">
            <div className="xx-stretch xx-vbox">
                {/*首次进来显示骨架*/}
            <Skeleton loading={loading} active={true}>
                <Tree checkable checkedKeys={d.user_access} treeData={nodeTrees} onCheck={onUserAccessChange} className="_nodelist xx-no-flex" fieldNames={{title:"node_name",key:"node_id",children:"children"}}/>
            </Skeleton>
            <Divider className="xx-no-flex"/>
            <Skeleton loading={loading} active={true}>
                    <Transfer
                        showSearch
                        rowKey={rcd => rcd.role_id}
                        dataSource={d.role_list}
                        targetKeys={d.role_ids}
                        titles={['未授权', '已授权']}
                        onChange={onRoleSelectChange}
                        render={(rcd) => rcd.role_name}
                        oneWay={true}
                        pagination
                    />
            </Skeleton>
            </div>
        </Spin>
    )

}

export default AuthPanel