import {  Skeleton, Menu } from 'antd';
//import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import PubSub from 'pubsub-js'
import { useEffect, useState } from 'react';
import Helper from '../../func';
const service=(p)=>{
    return Helper.serviceUrl('node/'+p);
}

/**
 * 
 * @param {selectID:''} props 
 * @returns 
 */
const Project = (props)=> {
    const [dd,setDD]=useState([]);
    const [loading,setLoading] = useState([]);

    useEffect(()=>{
      const url = service('project_list')
      Helper.http.get(url,{},function(res){
        
          
        
        res.result = res.result.map(element => {
          const data = {};
          data['label']=element['project_name'];
          data['key']=element['project_id'];
          if(Helper.isM()){
            data['style']={padding:"5px"}
          }
          return data
        });
        setDD(res.result||[]);
        setLoading(false)
      })
    },[])
    
    const mode='inline';//Helper.isM()?'horizontal':'inline';
    const msgTopic = props.msgTopic||false

    const onClickItem=(item)=>{
        if(msgTopic){
            PubSub.publish(props.msgTopic,{cmd:'switch_project',projectId:item.key})
        }
    }
    return (
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Menu
        mode={mode}
        style={{ width:"100%",borderRight:0,padding:0}}
        items={dd}
        onClick={onClickItem}
        defaultSelectedKeys={props.selectID}
        />
        </Skeleton>
    );
};

export default Project;