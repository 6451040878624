import {Button, Divider, Form, Input, message, Select, Skeleton, Typography} from 'antd';
import React, { useEffect, useState } from 'react';
import Helper from '../../func';
import * as PropTypes from "prop-types";
import {Option} from "antd/es/mentions";
//import PubSub from 'pubsub-js';
const { TextArea } = Input;
const requireRule=(msg='该项必须输入')=>{
  return {
    required: true,
    message: msg,
  }
}

const defaultData = {project_name:"",project_gateway:'',project_desc:''};

const { Text } = Typography;
Text.propTypes = {children: PropTypes.node};
const ProjectEditor = (props) => {
  const [loading,setLoading] = useState(true)
  const [form] = Form.useForm();
  const pushType = Form.useWatch('project_push_type', form);

  const isCreate = props.projectId==='+';
  


  useEffect(()=>{
    if(isCreate){
      //添加
      const propsDefault = (props.defaultData||{})
      form.setFieldsValue({...defaultData,...propsDefault})
      setLoading(false)
    }
    else if(props.projectId){
      //编辑
      const url = Helper.serviceUrl('project/details')
      Helper.http.get(url,{project_id:props.projectId},function(res){
        res.result = res.result||{};
        const data = {...defaultData,...res.result};
        data.project_push_type = data.project_callback_url==='mq'?'mq':'webhook';
        form.setFieldsValue(data)
        setLoading(false)
      })
    } 
  },[props.projectId,props.defaultData,isCreate]);

  useEffect( ()=>{
    if(pushType==='mq'){
      form.setFieldValue('project_callback_url','mq')
    }
    else{
      form.setFieldValue('project_callback_url','')
    }
  },[pushType])
 
  const onFinish = (values) => {
    const url = isCreate?Helper.serviceUrl('project/create'):Helper.serviceUrl('project/save')
    const param = isCreate? values : {...values,project_id:props.projectId}
    delete param.project_push_type;
    Helper.http.post(url,param,function(r){
      if(typeof props.afterSave==='function') props.afterSave();
      message.success(r.msg||'已保存')
      if(typeof props.afterSaved==='function')props.afterSaved();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Skeleton loading={loading} active >
      <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={defaultData}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
      >

        <Form.Item
            label="名称"
            name="project_name"
            rules={[requireRule()]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
            label="SSO重定向URL"
            name="project_redirect_url"
            rules={[requireRule()]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
            label="推送方式"
            name="project_push_type"
            rules={[requireRule()]}
        >
          <Select>
            <Option value="mq">消息推送</Option>
            <Option value="webhook">WebHook</Option>
          </Select>
        </Form.Item>
        <Form.Item
            label="推送地址"
            name="project_callback_url"
            hidden={(pushType==='mq')}
            rules={[requireRule()]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
            label="API网关"
            name="project_gateway"
            rules={[requireRule()]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
            label="首页"
            name="project_home_page"
        >
          <Input/>
        </Form.Item>
        <Form.Item name="project_desc"  label="备注" >
          <TextArea rows={4} name="project_desc"/>
        </Form.Item>

          <Divider></Divider>
        <div style={{textAlign:"right"}}>
          <Button type="primary" htmlType="submit" style={{margin:"auto",width:"160px"}}>
          提交
          </Button>
        </div>
      </Form>
    </Skeleton>
  );
};

export default ProjectEditor;