import { Button,  Divider,  Form, Input,Switch, message, Skeleton, Steps } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Helper from '../../func';
import Selector from '../fields/Selector';
//import SelectorField from '../fields/SelectorField';
//import PubSub from 'pubsub-js';
//const { TextArea } = Input;
const { Step } = Steps;
const service=(p)=>{
    return Helper.serviceUrl('user/'+p);
}


const UserCreator = (props) => {
  //const msgTopic = props.msgTopic||false;
  //const [dd,setDd] = useState({project_id:'',project_name:"",project_desc:''})
  const [formValues,setFormValues] = useState({});
  const [loading,setLoading] = useState(true)
  const [em,setEm]=useState({id:"",name:""});
  const [currentStep,setCurrentStep] = useState(0);

  useEffect(()=>{
    updateFormValues(props.defaultData||{})
    setLoading(false)
  },[]);

  const updateFormValues=(data)=>{
      const d ={...formValues,...data}
      setFormValues(d);
  }

 
  const onFinish = (values) => {
    const url = Helper.serviceUrl('user/create')
    const data = {...values,em_id:em.id}
    delete data.u_password_2;
    Helper.http.post(url,data,function(r){
      message.success(r.msg||'已保存')
      if(typeof props.afterSaved==='function')props.afterSaved();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onSelectEm=(emId,selItem)=>{
      updateFormValues({u_nickname:selItem.title,u_status:true})
      setEm({id:emId,name:selItem.title})
      setCurrentStep(currentStep+1)
  }

  const checkAccount=(val,call)=>{
      if(val.length>3) {
          Helper.mergeExec('uCreate_onChangeAccount', function () {
              Helper.http.get(service('check_account'), {account:val},function (res) {
                  call(res);
              });
          }, 1500)
      }
  }

  const rulesRequired = (msg="该项必须输入")=>({required: true, message: msg})
  const step0=()=>{
      const desc=(v)=>{
          return  [
                '工号:'+v.em_num,
                '状态:'+(v._em_status__text||v.em_status),
                //'性别:'+(v._em_sex__text||v.em_sex),
          ].join(" ")
      }
    return <Selector afterSelect={onSelectEm}
                     processItemData={(item)=>({key:item.em_id,avatar:item._em_avatar__url,title:item.em_name,description:desc(item),sex:item.em_sex})}
                     serviceUrl={Helper.serviceUrl('employee/get_list')}
                     searchFields={[
                        {value:"em_name",text:"姓名",default:true},
                        {value:"em_num",text:"工号"}
                    ]}
        />
  }

  const step1=()=>{
    return <Form
            name="basic"
            labelCol={{
            span: 8,
            }}
            wrapperCol={{
            span: 16,
            }}
            initialValues={formValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

              <Form.Item
              label="昵称"
              name="u_nickname"
              rules={[rulesRequired()]}
              >
                <Input autoComplete="off"/>
              </Form.Item>

            <Form.Item name="u_account" hasFeedback={true} label="账号" rules={[
                {
                    validator(_, value) {
                        value = value.replace(/\s+/,'');
                        if(value.length>3) {
                            return new Promise((resolve, reject) => {
                                checkAccount(value,(r)=>{
                                    console.log(r);
                                    if(!r.result)reject('账号已被使用');
                                    else resolve();
                                })
                                setTimeout(() => {
                                    reject('too young');
                                }, 1000)
                                setTimeout(() => {
                                    resolve();
                                }, 2000)
                            })
                        }else{
                            return Promise.reject(value.length>0?"不能少于三个字符":"该项必须输入");
                        }
                    },
                }
            ]} >
                <Input/>
            </Form.Item>
            <Form.Item name="u_status"  label="启用" valuePropName="checked">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                />
            </Form.Item>
            <Form.Item name="u_password"  label="密码" rules={[rulesRequired()]} >
                <Input.Password autoComplete="new-password"/>
            </Form.Item>


            <Form.Item
                    name="u_password_2"
                    autoComplete="new-password"
                    label="密码确认"
                    //dependencies={['u_account']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请输入再次输入密码!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('u_password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次密码不一致'));
                            },
                        }),
                    ]}
            >
                <Input.Password/>
            </Form.Item>

              <Divider/>
              <div style={{textAlign:"right"}}>
              <Button type="primary" htmlType="submit" style={{margin:"auto",width:"160px"}}>
                  提交
              </Button>
              </div>

        </Form>
  }

  return (
    <>
        <Steps
            type="navigation"
            size="small"
            current={currentStep}
            className="site-navigation-steps"
          >
            <Step title="选择员工" description={em.name? '已选择['+em.name+']':null}/>
            <Step title="创建账号"/>
        </Steps>
        <Divider/>
        <Skeleton loading={loading} active >
        {currentStep===0?step0():step1()}
        </Skeleton>
    </>
  );
};


export default UserCreator;