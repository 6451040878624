import React from 'react';
////V18
//import ReactDOM from 'react-dom/client';
//V17
import ReactDOM from 'react-dom';
import App from './App';
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';

import './index.css';
import reportWebVitals from './reportWebVitals';
//import {BrowserRouter,HashRouter} from 'react-router-dom'
import {HashRouter} from 'react-router-dom'
window._routerType_ = 'hash';

// //V18
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <div className='main_container'>
//     <BrowserRouter>
//       <App/>
//     </BrowserRouter>
//   </div>
// );

//17
ReactDOM.render(
    <div className='main_container'>
        <HashRouter>
            <App/>
        </HashRouter>
    </div>,
    document.getElementById('root')
);

reportWebVitals();
