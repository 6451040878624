import {Select} from "antd";
import React from "react";
const { Option } = Select;

const ComboBox = (props)=>{
    const options = [];
    for(let k in props.data){
        options.push(<Option value={k}>{props.data[k]||""}</Option>)
    }
    const param={...props}
    delete param.data;
    return <Select {...param}>
        {options}
    </Select>
}
export default ComboBox;
