import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
  } from '@ant-design/icons';
  import { Layout } from 'antd';
  import React, { useState } from 'react';
  
  import AppRoutes from '../../AppRoutes';
  import MainMenu from './Menu';
  
  const { Header,  Content,Sider } = Layout;
  
  export default ()=>{
    const [collapsed,setCollapse]= useState(false);
    return (
        <Layout className='main-view'>
          {/*<Sider collapsedWidth={isM?0:"80px"} trigger={null} collapsible collapsed={collapsed}>*/}
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="_logo" />
            <MainMenu></MainMenu>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="_header"
            >
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapse(!collapsed),
              })}
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
                  overflowY:"scroll"
              }}
            >            
              <AppRoutes/>
            </Content>
          </Layout>
        </Layout>
      );
  };