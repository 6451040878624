import {Avatar, Button, Drawer, List, Skeleton, Space, Modal, Typography, Menu, Dropdown, notification} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import ProjectEditor from '../cmp/project/Editor'
import Helper from "../func"
const { confirm } = Modal;
const { Text} = Typography;

const ProjectPage = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [editing, setEditing] = useState(0);
  const [creating, setCreating] = useState(0);

  const [list, setList] = useState([]);
  const refresh=()=>{
    const url = Helper.serviceUrl('project/get_list')
    Helper.http.get(url,{},function(res){
        const listData = res.result.map(e=>{
          e.key = e.project_id;
          return e;
        })
        setInitLoading(false);
        setList(listData);
    })
  }
  useEffect(() => {refresh()}, []);
  const onRemove=(projectId)=>{
    const url = Helper.serviceUrl('project/remove')
    confirm({
      title: '确认移除吗',
      icon: <ExclamationCircleOutlined />,
      content: '移除后，不可恢复，请谨慎操作',
      okText: '移除',
      cancelText: '取消',
      okType: 'danger',
      onOk() {
        Helper.http.get(url,{project_id:projectId},function(res){
          refresh()     
        })
      }
    });
    
  }

  const resetSecret=(id)=>{
      Helper.http.get(Helper.serviceUrl('project/reset_secret'),{project_id:id},function (res){
          notification.success({
              message:"秘钥已重置",
              description:res.result,
              duration:0
          });
      })
  }
    const getRcConfig=(id)=>{
        Helper.http.get(Helper.serviceUrl('project/get_rc_config'),{project_id:id},function (res){
            notification.success({
                message:"配置如下",
                description:res.result,
                duration:0
            });
        })
    }



  return (
    <>
      <Space><Button onClick={()=>setCreating(true)}>创建</Button></Space>
      <List
        loading={initLoading}
        itemLayout="horizontal"      
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            actions={[
            <Text className="xx-link" onClick={()=>{setEditing(item.project_id)}}>编辑</Text>,
            <Text className="xx-link" onClick={()=>{onRemove(item.project_id)}} type="danger">移除</Text>,
            <Dropdown
                overlay={<Menu
                    items={[
                        {
                            key: '1',
                            label: (<Text className="xx-link" onClick={()=>alert("重置之后可查看")}>查看秘钥</Text>),
                        },
                        {
                            key: '2',
                            label: (<Text className="xx-link" onClick={()=>resetSecret(item.project_id)}>重置秘钥</Text>),
                        },
                        {
                            key: '3',
                            label: (<Text className="xx-link" onClick={()=>getRcConfig(item.project_id)}>下载配置</Text>),
                        }
                    ]}
                />} arrow
            >
              <text className="xx-link">...</text>
            </Dropdown>
          ]}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar src={item.project_logo} />}
                title={item.project_name}
                description={'< '+item.project_id+' > '+item.project_desc}
              />
            </Skeleton>
          </List.Item>
        )}
      />
      <Drawer title="编辑" width={500} placement="right" onClose={()=>setEditing(0)} visible={!!editing}>
          <ProjectEditor projectId={editing} afterSaved={refresh}></ProjectEditor>
      </Drawer>
      <Drawer title="创建" width={500} placement="right" onClose={()=>setCreating(0)} visible={!!creating}>
          <ProjectEditor projectId="+" afterSaved={refresh}></ProjectEditor>
      </Drawer>
    </>
  );
};

export default ProjectPage;