import AppPc from './cmp/main/MainPc';
import AppM from './cmp/main/MainM';
import Helper from './func.js';
import { Context } from './cmp/Context';
import './App.css';
import { useEffect, useState } from 'react';
import LoginPage from "./pages/LoginPage";
import {Drawer, message} from "antd";
import {useNavigate} from "react-router-dom";

const defaultSetting  ={
    isM:Helper.isM(),
    u_status:{enabled:9,disabled:1},
    login_user:{id:0,account:'未登录',nickname:'未登录'},
    access:['home']

}

export default ()=>{

    const navigate = useNavigate();
    const [setting,setSetting] = useState(defaultSetting);
    const updateSetting=(data)=>{
        console.log(data);
        setSetting({...setting,...data})
    }

    const afterLogin=(user,access)=>{
        if(user){
            updateSetting({login_user:user,access:access||[]})
        }
    }

    useEffect(()=>{
        Helper.http.get(Helper.serviceUrl('login/load_setting'),{},(res)=>{
            const {result} = res;
            if(!!result) {
                if(!result.login_user){
                    delete result.login_user;
                }
                if(!result.access){
                    delete result.access;
                }
                //console.log(result)
                updateSetting(result)
            }
        })
    },[])

    useEffect(function (){
        //const t = Math.random();
        const timer = setInterval(()=>{
            //console.log(setting.access);
            if(window._routerType_==='hash'){
                //兼容Hash路由
                const hash = window.location.hash.replace(/^#\/*|\?.*$|\/+$/g,'');
                if(hash !=='' && hash !=='home' && setting.access.indexOf(hash)<0){
                    message.error('权限不足');
                    navigate(Helper.pathname('/'));
                }
            }else {
                //兼容Browser路由
                const pathName = window.location.pathname.replace(/^\/|\/$/, '')
                if (pathName !== '' && pathName !== 'home' && setting.access.indexOf(pathName) < 0) {
                    message.error('权限不足');
                    navigate(Helper.pathname('/'));
                }
            }
        },1000)
        return ()=>{
            clearInterval(timer);
        }
    },[setting])

    const createApp=()=>{
        if(setting.login_user && setting.login_user.id) {
            return Helper.isM() ? <AppM/> : <AppPc/>
        }
        return ''
    }
    return (
        <Context.Provider value={setting}>
            {createApp()}
            <Drawer title="请先登录" placement="right" maskClosable={false} width="100%" closable={false} visible={!setting.login_user.id}>
                <LoginPage afterLogin={afterLogin}/>
            </Drawer>
        </Context.Provider>
    );
}