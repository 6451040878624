
import { useSearchParams } from "react-router-dom";
import {Row, Col, Drawer} from 'antd';
import React, { useEffect } from "react";
import RoleAccess from "../cmp/role/Access";
import RoleList from "../cmp/role/List";
import Helper from "../func";
const RolePage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const roleId = searchParams.get('id')

    const onEffect=()=>{}

    useEffect(onEffect,[]);
    if(Helper.isM()){
        return (
            <>
                <RoleList msgTopic="role_page" onClickAuth={(v)=>{setSearchParams('?id='+v,{replace:true})}}/>
               <Drawer visible={roleId>0} onClose={setSearchParams('?id=0',{replace:true})}>
                   <RoleAccess roleId={roleId} msgTopic="role_page" />
               </Drawer>
            </>
        );
    }else{
        return (
            <Row justify="center" gutter={24} align="top" style={{height:"100%","alignItems":"stretch"}}>
                <Col span={12} style={{borderRight:"1px solid #f0f0f0"}}>
                    <RoleList msgTopic="role_page" onClickAuth={(v)=>{setSearchParams('?id='+v,{replace:true})}}/>
                </Col>
                <Col span={12}><RoleAccess roleId={roleId} msgTopic="role_page" /></Col>
            </Row>
        );
    }
};

export default RolePage;