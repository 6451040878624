//import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { EditableProTable} from '@ant-design/pro-components';
import {Popconfirm,Typography} from 'antd';
import {useRef, useState} from 'react';
import request from 'umi-request';
import Helper from "../../func";
let _props={}
let _cnt_ = {list_param:{}};
const { Text } = Typography;
const handleDelete=(roleId,action)=>{
    Helper.http.get(Helper.serviceUrl('role/delete'), {role_id:roleId},function (res){
        action.reload()
    })//ref.current.reloadAndRest();
}

const columns = [
    {
        title:"行号",
        dataIndex: 'index',
        key: 'indexBorder',
        //valueType: 'indexBorder',
        editable:false,
        search:false,
        render: (text, record, _, action)=>{
            return _+1
        },
        width: 48
    },
    {
        title: '名称',
        dataIndex: 'role_name',
        copyable: true,
        ellipsis: true,

        formItemProps: {
            rules: [
                {
                    required: true,
                    message: '此项为必填项',
                },
            ],
        },
    },
    {
        title: '操作',
        valueType: 'option',
        key: 'option',
        width: 150,
        render: (text, record, _, action)=>{
            return [
                <Text className="xx-link" key="editable" onClick={() => {
                    action.startEditable(record.role_id)
                }}>编辑</Text>,
                <Text className="xx-link" onClick={()=>{_props.onClickAuth(record.role_id,record)}} rel="noopener noreferrer" key="view">授权</Text>,
                <Popconfirm
                    title="确定要删除吗，删除后不可恢复同时将回收相关权限，请谨慎操作"
                    onConfirm={()=>{handleDelete(record.role_id,action)}}
                    okText="删除"
                    cancelText="不,我误操作"
                >
                    <Text className="xx-link" key="del">删除</Text>
                </Popconfirm>
            ]
        },
    },
];



export default (props) => {
    _props = props

    const [editableKeys, setEditableRowKeys] = useState([]);
    const actionRef = useRef();



    const handleSave=(ref,rowKey,data)=>{
        if((''+rowKey).indexOf('+')===0){
            Helper.http.post(Helper.serviceUrl('role/create'), {role_name:data.role_name},function (res){
                ref.current.reloadAndRest();
            })
        }
        else{
            Helper.http.post(Helper.serviceUrl('role/save'), {role_name:data.role_name,role_id:data.role_id},function (res){
                ref.current.reloadAndRest();
            })
        }
    }
    //const editableFormRef = useRef();
    return (
        <EditableProTable
            //editableFormRef={editableFormRef}
            rowKey="role_id"
            columns={columns} actionRef={actionRef} cardBordered
            request={
                (params = {}) => {
                    const param = Helper.resetKeys(params,{current:'page',pageSize:'page_size'});
                    _cnt_.list_param = param;
                    return  request(Helper.serviceUrl('role/get_list'), {
                        params:param
                    });
                }
            }
            //onSubmit={(param)=>{console.log(param)}}

            //editable={{type: 'multiple',}}
            editable={{
                type: 'multiple',
                editableKeys,
                onSave: (rowKey, data, row) => {
                    handleSave(actionRef,rowKey,data);
                },
                onDelete:(rowKey,data)=>{
                    handleDelete(rowKey,actionRef.current);
                },
                onChange: setEditableRowKeys,
            }}
            columnsState={{
                    persistenceKey: 'pro-table-singe-demos',
                    persistenceType: 'localStorage',
                    onChange(value) {
                        console.log('value: ', value);
                    },
            }}
            search={{
                labelWidth: 'auto',
            }}
            ListToolBarProps={{
                settings:[]
            }}
            options={{
                setting: false,
            }}
/*
            form={{
            // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
                syncToUrl: (values, type) => {
                    if (type === 'get') {
                        return Object.assign(Object.assign({}, values), { created_at: [values.startTime, values.endTime] });
                    }
                    return values;
                },
            }}
*/
            pagination={{
                pageSize: 10,
                onChange: (page) => console.log(page),
            }}

            dateFormatter="string"
            headerTitle="权限组列表"
            recordCreatorProps={
                {
                    position: 'top',
                    record: () => ({ role_id:'+'+(Math.random() * 1000000).toFixed(0) }),
                }//: false
            }
        />);
};
