import {Table, Skeleton, Button, Row, Divider, Col, Form, Space, Select, Input, Typography, Cascader} from 'antd';
import EmployeeEditor from './Editor';
import { PlusCircleOutlined } from '@ant-design/icons';
//import {useState} from 'react'
import EmployeeList from './List';
import React from "react";
import { Context } from '../Context';

const { Search } = Input;
const { Option } = Select;

const { Text } = Typography;


class EmployeeListPc extends EmployeeList{
    columns = [
      {
        title: '姓名',
        dataIndex: 'em_name'
      },
      // {
      //   title: '性别',
      //   dataIndex: 'em_sex',
      //   render: (text) => <a>{text===1? '男':'女'}</a>,
      // },
      // {
      //   title: '出生日期',
      //   dataIndex: 'em_birth',
      // },
      {
        title: '入职日期',
        dataIndex: 'em_commencement_date',
      },
      {
          title: '在职',
          dataIndex: 'em_status',
          render:(v)=>{
              return this.renderStatus(v);
          }
      },
      {
        title: '工号',
        dataIndex: 'em_num',
      },
      {
        title: '单位/组织',
        dataIndex: 'em_org',
      },
      {
        title: '部门',
        dataIndex: 'em_department',
      },
      {
        title: '手机号',
        dataIndex: 'em_mobile',
      },
      {
        title: '座机号/分机号',
        dataIndex: 'em_tel',
      },
      // {
      //   title: '邮箱',
      //   dataIndex: 'em_email',
      // },
      {
        title: '操作',
        key: 'operation',
        fixed: 'right',
        width: 100,
        //render: (_, record) =>
        // //dataSource.length >= 1 ? (
        //   <div>
        //     <Popconfirm title="确认要删除吗?" onConfirm={() => this.removeItem(record.em_id)}>
        //       <a>删除</a><br/>
        //     </Popconfirm>
        //     <a onClick={() => this.editItem(record.em_id)}>修改</a>
        //   </div>
        // //) : null,
        render: (_, record) => <Text className="xx-link" onClick={() => this.editItem(record.em_id,record)}>修改</Text>

      },
    ];

    onSearch=(values)=>{
        this.setState({searchParam:values,loading:true});
        setTimeout(()=>{this.loadData()},300)
    }
    searchFormRef = React.createRef();
    static contextType = Context;
    header =()=>{
        return <Row>
            <Col span={18}>
                <Form ref={this.searchFormRef}
                      onFinish={this.onSearch} initialValues={this.state.searchParam}
                >
                    <Space>
                        <Form.Item label="组织架构" name="em_org_info">
                            <Cascader options={this.context.org_tree||{}} placeholder="Please select"/>
                        </Form.Item>
                        <Form.Item name="em_status" style={{width:"100px"}}>
                            <Select defaultValue="-">
                                <Option value="-">不限制</Option>
                                <Option value="1">{this.renderStatus(1)}</Option>
                                <Option value="2">{this.renderStatus(2)}</Option>
                                <Option value="9">{this.renderStatus(9)}</Option>
                            </Select>
                        </Form.Item>
                        <Input.Group compact>
                            <Form.Item name="field" style={{width:"30%"}}>
                                <Select defaultValue="em_name">
                                    <Option value="em_name">姓名</Option>
                                    <Option value="em_num">工号</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="value" style={{width:"66%"}} >
                                <Search placeholder="input search text" onSearch={()=>this.searchFormRef.current.submit()} enterButton />
                            </Form.Item>
                        </Input.Group>
                    </Space>
                </Form>
            </Col>
            <Col span={6}>
                <Button icon={<PlusCircleOutlined />} onClick={()=>this.createItem()} style={{float:"right"}}>创建新员工</Button>
            </Col>
        </Row>
    }

    render(){
        const editEm = this.state.edit_em||{};
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User',
                name: record.name,
            }),
        };
        return <>
            {this.header()}
            <Divider />
            <Skeleton type='list' statistic={false} loading={this.state.loading} active paragraph={{ rows: 10 }}>
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  rowKey="em_id"
                  columns={this.columns}
                  dataSource={this.state.data}
                  pagination={{
                      position: ["bottomRight"],
                      total:this.state.total,
                      defaultCurrent:this.state.page,
                      //current:this.state.page,
                      hideOnSinglePage:true,
                      pageSize:this.state.page_size,
                      showSizeChanger:false,
                      onChange:(page,ps)=>{
                        this.toPage(page,ps)
                      }
                  }}
                />
            </Skeleton>
            {/*
            <Drawer width="50%"
              contentWrapperStyle={{minWidth:"380px"}} placement="right"
              title={("正在编辑【"+(this.state.edit_em.em_name||'')+'】的信息')}
              visible={this.state.edit_em.em_id>0}
              onClose={()=>{this.setState({edit_em:{}})}}
              extra={<Button type="primary" onClick={this.endEdit}>保存</Button>}
              >
               {this.state.edit_em.em_id>0?<EmployeeEditor emId={this.state.edit_em.em_id||0} noButtons={true}/>:null}
            </Drawer>
            */}
            <EmployeeEditor afterSaved={()=>{this.closeEditor();this.loadData()}} emId={editEm.em_id||-1} drawerCfg={{visible:!!editEm.em_id,onClose:()=>this.closeEditor()}}/>
        </>
    }

    renderStatus(status) {
        status = status+"";
        const statusList = {"1":"已离职","2":"停职中","9":"在职"}
        if(status==="1") {
            return <Text type="secondary">已离职</Text>
        }else{
            return  statusList[status]||status;
        }
    }
}

export default EmployeeListPc;