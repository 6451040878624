import { Component } from 'react';
import { Drawer } from 'antd';
import Helper from "../func";
class Cmp extends Component{
    constructor(props){
        super(props)
    }
    _H_(){
        return Helper
    }

    _renderToPopup(cmp,popup){
        if(popup||false){
            popup = typeof popup==='boolean'?{}:popup;
            popup.visible = popup.visible||false
            return <Drawer 
                width="50%" 
                contentWrapperStyle={{minWidth:"380px"}} 
                placement="right"
                {...popup}
            >
            {cmp} 
            </Drawer>
        }else{
            return cmp;
        }
    }

}

export default Cmp