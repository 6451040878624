import {Button,  Form, Input, message} from 'antd';
import React from 'react';
import Helper from "../func";

const LoginPage = (props) => {
    const onFinish = (values) => {
        Helper.http.post(Helper.serviceUrl('login/check'),values,function (r){
            const {user,access} = r;
            if(r.success && user && user.id>0){
                props.afterLogin(user,access||[])
                message.success("登录成功")
            }else{
                message.error(r.msg||'登录失败')
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            style={{width:"300px",margin:"auto"}}
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="用户名"
                name="account"
                rules={[
                    {
                        required: true,
                        message: '请输入用户名!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="密码"
                name="password"
                rules={[
                    {
                        required: true,
                        message: '请输入密码!',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    登录
                </Button>
            </Form.Item>
        </Form>
    );
};

export default LoginPage;